/* General styling remains the same for desktop */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 10px 20px; /* Reduced padding for smaller screens */
  color: white;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

.logo-image {
  height: 40px;
  margin-right: 10px;
}

nav {
  display: flex;
  align-items: center;
}

/* Styling for the nav links */
nav a {
  color: white;
  text-decoration: none;
  margin: 0 15px;
}

/* Dropdown menu styling */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: #333333;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;
}

.droptxt {
  background-color: #333333;
  color: white;
  border: none;
  font-size: 16px;
  padding: 8px 16px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #555;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  /* Stack the logo and nav items vertically on mobile */
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
  }

  /* Center the logo */
  .logo {
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
  }

  /* Make nav take up full width and stack the items vertically */
  nav {
    flex-direction: column;
    width: 100%;
  }

  /* Adjust dropdown buttons */
  .dropbtn {
    width: 100%;
    text-align: left;
  }

  .droptxt {
    width: 100%;
    text-align: left;
  }

  nav a {
    margin: 5px 0;
    text-align: left;
  }

  /* Adjust dropdown content positioning for mobile */
  .dropdown-content {
    position: relative;
    min-width: 100%;
  }
}