/* src/components/HeroBanner.css */

.hero-banner {
  width: 100%;
  height: 300px; /* Default height */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Helps to position text over the banner */
}

.hero-image {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the banner area neatly */
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .hero-banner {
      height: 200px; /* Reduce height on mobile */
  }

  .hero-image {
      width: 100%;
      height: auto;
  }
}

@media (max-width: 480px) {
  .hero-banner {
      height: 150px; /* Further reduce height for very small screens */
  }
}