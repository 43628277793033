/* CalendarIntegration.css */
.calendar-buttons {
    display: flex;
    gap: 5px;
  }
  
  .calendar-buttons button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .calendar-buttons button:hover {
    background-color: #0056b3;
  }
  
  .calendar-buttons button:focus {
    outline: none;
  }
  