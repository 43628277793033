.blog-container {
    text-align: center;
  }
  
  .blog-list {
    list-style-type: none;
    padding: 0;
  }
  
  .blog-list-item {
    display: inline-block;
    width: 300px;
    margin: 20px;
    text-align: left;
    vertical-align: top;
  }
  
  .blog-image {
    width: 100%;
    height: auto;
  }
  
  .blog-post-container {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
  }
  
  .blog-post-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  