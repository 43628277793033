/* Responsive adjustments for mobile devices */
@media only screen and (max-width: 768px) {
  /* Make the page container column-oriented */
  .page-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  /* Adjust ad component widths */
  .ad-component-left,
  .ad-component-right {
    width: 100%;
    margin-top: 10px;
  }

  /* Adjust the content width for small screens */
  .content {
    max-width: 100%;
    margin: 0;
    padding: 10px;
  }

  /* Make table scrollable on smaller screens */
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  /* Improve table readability */
  .table-striped th,
  .table-striped td {
    padding: 8px 4px;
    font-size: 14px;
  }
}

/* Additional adjustments for ultra-small screens */
@media only screen and (max-width: 480px) {
  /* Further reduce padding for very small screens */
  .table-responsive .table th,
  .table-responsive .table td {
    padding: 6px 2px;
    font-size: 12px;
  }

  /* Hide less important columns on extra small screens */
  .table th:nth-child(4),
  .table td:nth-child(4),
  .table th:nth-child(5),
  .table td:nth-child(5) {
    display: none;
  }

  /* Adjust container and content styling */
  .container, .content-container {
    padding: 5px;
  }
}