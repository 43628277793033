.filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Ensures the form controls take up full width on small screens */
@media only screen and (max-width: 480px) {
  .filter-container .form-control,
  .filter-container .form-check {
    flex: 1 1 100%;
    margin-bottom: 10px;
  }
}