.region-page {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  max-width: 900px;
  margin: 0 auto;
}

.region-page h1,
.region-page h2 {
  color: #004080;
  margin-bottom: 10px;
  text-align: center;
}

.region-page p {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.region-page table-container {
  margin-bottom: 40px;
}

.region-page table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
  font-size: 14px;
  max-width: 100%;
}

.region-page th, 
.region-page td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.region-page th {
  background-color: #f8f8f8;
  color: #333;
}

.region-page tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.region-page tbody tr:hover {
  background-color: #f1f1f1;
}

.region-page section {
  margin-bottom: 40px;
}

.region-page section h2 {
  border-bottom: 2px solid #004080;
  padding-bottom: 5px;
}

@media (max-width: 768px) {
  .region-page table, 
  .region-page th, 
  .region-page td {
    font-size: 12px;
  }

  .region-page {
    padding: 10px;
  }
}
