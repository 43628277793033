/* Styles.css */

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  p {
    text-align: center;
    color: #666;
  }
  
  .paklijst {
    list-style-type: none;
    padding: 0;
  }
  
  .paklijst-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .paklijst-item label {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }
  
  .paklijst-item input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .print-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .print-button:hover {
    background-color: #0056b3;
  }
  