/* Footer.css */
.footer {
    background-color: #343a40;
    color: white;
    padding: 10px 0;
    margin-top: 20px;
  }
  
  .footer .container {
    text-align: center;
  }
  
  .footer p {
    margin: 0;
  }
  