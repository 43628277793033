.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: left;
  }
  
  .about-us-container h1 {
    text-align: center;
  }
  
  .about-us-container p {
    margin-bottom: 20px;
    line-height: 1.6;
  }
  