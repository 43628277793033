.App {
  text-align: center;
}

body {
  background-color: #f0edea; /* Replace with your desired background color */
}

.main-content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.ad-container {
  width: 150px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-container {
  flex-grow: 1;
}

.container {
  text-align: left;
  margin: 0 auto;
  max-width: 1200px;
}

.table {
  width: 100%;
  margin-top: 20px;
}

.table th, .table td {
  padding: 10px;
  text-align: left;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.ad-container img, .ad-container video {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.ad-container p {
  text-align: center;
}

/* Mobile adjustments for main content */
@media only screen and (max-width: 768px) {
  .main-content {
    flex-direction: column;
    padding: 10px;
  }

  .ad-container {
    width: 100%;
    margin: 10px 0;
  }
}