.cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 15px;
    text-align: center;
    z-index: 1000;
  }
  
  .cookie-buttons {
    margin-top: 10px;
  }
  
  .cookie-buttons button {
    margin: 0 5px;
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .cookie-buttons button:hover {
    background-color: #0056b3;
  }
  
  .cookie-buttons button:nth-child(2) {
    background-color: #6c757d;
  }
  
  .cookie-buttons button:nth-child(2):hover {
    background-color: #5a6268;
  }
  